<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17" fill="none">
        <path fill="currentColor"
            d="M14.727 16.501H3.273a.819.819 0 0 1-.818-.814V8.359H0L8.452.713a.82.82 0 0 1 1.104 0l8.452 7.646h-2.454v7.328a.819.819 0 0 1-.819.814h-.008Zm-4.909-1.628h4.091V6.86L9 2.415 4.09 6.86v8.013h4.092V9.987h1.636v4.886Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
